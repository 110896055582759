import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "assets/images/logo.svg";
import shards from "assets/images/shards/shards.png";
import {
  logoutIcon,
  logoIcon,
  navCloseIcon,
  notifyIcon,
  packdrowIcon,
  polygonIcon,
  roobetIcon,
  starIcon,
  avatarIcon,
  shard2DIcon,
  chevronBottom,
  crownIcon,
  crownIcon2,
  trashIcon,
} from "./SVG";
import { AnimatePresence, motion } from "framer-motion";
import playRoobet from "assets/images/navbar/playRoobet.png";
import formPic from "assets/images/navbar/formPic.png";
import loginPic from "assets/images/navbar/loginPic.png";
import ranksPic from "assets/images/navbar/ranksPic.png";
import jack from "assets/images/navbar/jack.png";
import watch from "assets/images/navbar/watch.png";
import jack_ticket from "assets/images/navbar/jack_ticket.png";
import jack_coins from "assets/images/navbar/jack_coins.png";
import packdraw from "assets/images/navbar/packdraw.png";
import packdrawLight from "assets/images/navbar/packdrawLight.png";
import packLoginPic from "assets/images/navbar/packLoginPic.png";
import leaderboard from "assets/images/navbar/leaderboard.png";
import rolex from "assets/images/navbar/rolex.png";
import iphone from "assets/images/navbar/iphone.png";
import deposit from "assets/images/navbar/deposit.png";
import shard from "assets/images/navbar/shard.png";
import clouds from "assets/images/navbar/clouds.png";
import vipBg from "assets/images/navbar/vipBg.png";
import vipSmall from "assets/images/navbar/vipSmall.png";
import star1 from "assets/images/navbar/star1.png";
import star2 from "assets/images/navbar/star2.png";
import star3 from "assets/images/navbar/star3.png";
import { languageList, vipBadges, vipRankNames } from "utils/constantLists";
import LanguageSelect from "components/inputs/LanguageSelect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  logoutUser,
  setUnreadNotification,
  updateUserInfo,
} from "store/redux/authSlice";
import { getUserProfile } from "services/accountService";
import { formatBalance, formatNumberComma } from "utils/globalFunc";
import Notification from "./Notification";
import { clearNotifications } from "services/notificationService";
import { handleToast } from "utils/toasts";

export default function Header({
  setModal,
  modal,
  menu,
  setMenu,
  setBonusData,
}) {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(languageList[0]);
  const [roobetHov, setRoobetHov] = useState(false);
  const [packdrowHov, setPackdrowHov] = useState(false);
  const [mobDropdown, setMobDropdown] = useState(null);
  const [diffHeader, setDiffHeader] = useState(false);
  const [noBgHeader, setNoBgHeader] = useState(false);
  const [noBgHeaderHov, setNoBgHeaderHov] = useState(false);
  const dispatch = useDispatch();
  const headerRef = useRef(null);
  const { pathname } = useLocation();
  useEffect(() => {
    const windowHov = ({ target }) => {
      if (headerRef.current && !headerRef.current.contains(target)) {
        setRoobetHov(false);
        setPackdrowHov(false);
      }
    };

    if (roobetHov || packdrowHov) {
      window.addEventListener("mouseover", windowHov);
      setNoBgHeaderHov(true);
    } else {
      window.removeEventListener("mouseover", windowHov);
      setNoBgHeaderHov(false);
    }

    return () => window.removeEventListener("mouseover", windowHov);
  }, [roobetHov, packdrowHov]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language.code);
  };
  const updateProfileData = async () => {
    const userData = await getUserProfile();
    if (userData === false) return;
    // console.log("userData", userData);
    dispatch(updateUserInfo(userData?.data));
  };

  useEffect(() => {
    if (auth?.isLogged) {
      updateProfileData();
    }
  }, [auth?.isLogged]);
  useEffect(() => {
    if (pathname === "/vip") {
      setDiffHeader(true);
    } else if (pathname === "/leaderboard") {
      setNoBgHeader(true);
      setDiffHeader(false);
    } else {
      setDiffHeader(false);
      setNoBgHeader(false);
    }
  }, [pathname]);

  return (
    <>
      <div
        className={
          "header " +
          (diffHeader ? "diffHeader " : "") +
          (noBgHeader ? "noBgHeader " : "") +
          (noBgHeaderHov ? "noBgHeaderHov " : "")
        }
        ref={headerRef}
      >
        <div className="auto__container">
          <div className="header__inner">
            <div className="header__inner-content">
              <Link
                to={"/"}
                className="header__inner-logo"
                aria-label="Homepage"
                onMouseOver={() => {
                  setPackdrowHov(false);
                  setRoobetHov(false);
                }}
              >
                <div className="header__inner-logo-ellipse"></div>
                <div className="header__inner-logo-ellipse2"></div>
                {logoIcon}
              </Link>
              <div className={"nav " + (menu ? "active" : "")}>
                <div className="nav__inner">
                  {auth?.isLogged ? (
                    <div className="nav__more">
                      <div
                        className="nav__more-info"
                        onClick={() =>
                          navigate("/profile", { state: "account" })
                        }
                      >
                        <div className="nav__more-avatar">
                          {auth?.user?.profilePicture ? (
                            <img src={auth?.user?.profilePicture} alt="" />
                          ) : (
                            avatarIcon
                          )}
                        </div>
                        <div className="nav__more-content">
                          <div className="nav__more-content-name">
                            <h4>{auth?.user?.username}</h4>
                          </div>
                          <div className="nav__more-content-rank">
                            <div className="nav__more-content-rank-image">
                              <img
                                src={
                                  auth?.user?.rank?.vipRank === 0
                                    ? vipBadges[0]
                                    : vipBadges[auth?.user?.rank?.vipRank - 1]
                                }
                                alt="rank"
                              />
                            </div>
                            <h6>
                              {auth?.user?.rank?.vipRank === 0
                                ? vipRankNames[0]
                                : vipRankNames[auth?.user?.rank?.vipRank - 1]}
                            </h6>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="nav__more-btn"
                          onClick={() => dispatch(logoutUser())}
                        >
                          {logoutIcon}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="nav__btns">
                      <button
                        type="button"
                        className="nav__btn"
                        onClick={() => setModal("register")}
                      >
                        <p>{t("register")}</p>
                      </button>
                      <button
                        type="button"
                        className="nav__btn"
                        onClick={() => setModal("login")}
                      >
                        <p>{t("login")}</p>
                      </button>
                    </div>
                  )}

                  <div
                    className={
                      "nav__inner-link roobet " + (roobetHov ? "hov" : "")
                    }
                    onMouseOver={() => {
                      setPackdrowHov(false);
                      setRoobetHov(true);
                    }}
                  >
                    <div className="nav__inner-link-content">
                      <p>{t("roobet")}</p>
                    </div>
                    <div
                      className={
                        "nav__dropdown " +
                        (roobetHov ? "active" : "") +
                        (mobDropdown === "roobet" ? " mobActive " : "")
                      }
                    >
                      <div
                        className="nav__dropdown-title roobet"
                        onClick={() => {
                          if (mobDropdown === "roobet") {
                            setMobDropdown(null);
                          } else {
                            setMobDropdown("roobet");
                          }
                        }}
                      >
                        {roobetIcon}
                        <div className="nav__dropdown-title-chevron">
                          {chevronBottom}
                        </div>
                        <p>{t("roobet")}</p>
                      </div>
                      <div
                        className={
                          "nav__dropdown-inner " +
                          (mobDropdown === "roobet" ? "mobActive" : "")
                        }
                      >
                        <a
                          href="https://roobet.com/?ref=foss"
                          target="_blank"
                          className="nav__dropdown-play"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            if (window.innerWidth <= 840) {
                              e.preventDefault();
                              setModal("register-roobet");
                            }
                          }}
                        >
                          <div className="nav__dropdown-play-title">
                            {polygonIcon}
                            <h6>{t("playRoobet")}</h6>
                          </div>
                          <div className="nav__dropdown-play-image">
                            <img src={playRoobet} alt="Play Roobet" />
                          </div>
                        </a>
                        <div className="nav__dropdown-col">
                          <div
                            className="nav__dropdown-item"
                            onClick={() => {
                              setMenu(false);
                              setModal("register-roobet");
                            }}
                          >
                            <div className="nav__dropdown-item-title">
                              <h5>?</h5>
                              <h6>{t("howToRegister")}</h6>
                            </div>
                            <div className="nav__dropdown-item-bg"></div>
                            <div className="nav__dropdown-item-bg hov"></div>
                            <div className="nav__dropdown-item-image">
                              <img src={formPic} alt="Form Registration" />
                            </div>
                            <div className="nav__dropdown-item-image2">
                              <img src={loginPic} alt="Login" />
                            </div>
                            <div className="nav__dropdown-item-image2 hov">
                              <img src={loginPic} alt="Login Hover" />
                            </div>
                          </div>
                          <div
                            className="nav__dropdown-item"
                            onClick={() => {
                              // if (auth?.isLogged) {
                              //   // navigate("/profile", { state: "vip" });
                              //   navigate("/vip");
                              // } else {
                              //   setModal("register");
                              // }
                              navigate("/vip");
                              setMenu(false);
                            }}
                          >
                            <div className="nav__dropdown-item-title">
                              {starIcon}
                              <div className="nav__dropdown-item-title-body">
                                <h6>{t("vipRewards")}</h6>
                                <div className="nav__dropdown-item-title-progress">
                                  <div className="nav__dropdown-item-title-progress-inner"></div>
                                </div>
                              </div>
                            </div>
                            <div className="nav__dropdown-item-bg black"></div>
                            <div className="nav__dropdown-item-bg black hov"></div>
                            <div className="nav__dropdown-item-ranks">
                              <img src={ranksPic} alt="Ranks" />
                            </div>
                          </div>
                        </div>
                        <div className="nav__dropdown-col">
                          <div
                            className="nav__dropdown-jackpot"
                            onClick={() => {
                              setMenu(false);
                              navigate("/leaderboard");
                            }}
                          >
                            <div className="nav__dropdown-jackpot-ticket">
                              <img src={jack_ticket} alt="ticket" />
                            </div>
                            <div className="nav__dropdown-jackpot-coins">
                              <img src={jack_coins} alt="coins" />
                            </div>
                            <div className="nav__dropdown-jackpot-image">
                              <img src={watch} alt="New Jackpot" />
                            </div>
                            <div className="nav__dropdown-jackpot-content">
                              <h4>NEW</h4>
                              <h5>Leaderboard</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      "nav__inner-link packdrow " + (packdrowHov ? "hov" : "")
                    }
                    onMouseOver={() => {
                      setRoobetHov(false);
                      setPackdrowHov(true);
                    }}
                  >
                    <div className="nav__inner-link-content">
                      <p>Packdraw</p>
                    </div>
                    <div
                      className={
                        "nav__dropdown pack " +
                        (packdrowHov ? "active" : "") +
                        (mobDropdown === "packdraw" ? " mobActive " : "")
                      }
                    >
                      <div
                        className="nav__dropdown-title pack"
                        onClick={() => {
                          if (mobDropdown === "packdraw") {
                            setMobDropdown(null);
                          } else {
                            setMobDropdown("packdraw");
                          }
                        }}
                      >
                        {packdrowIcon}
                        <div className="nav__dropdown-title-chevron">
                          {chevronBottom}
                        </div>
                        <p>{t("packdraw")}</p>
                      </div>
                      <div
                        className={
                          "nav__dropdown-inner " +
                          (mobDropdown === "packdraw" ? "mobActive" : "")
                        }
                      >
                        <a
                          href="https://packdraw.com/?ref=foss"
                          target="_blank"
                          className="nav__dropdown-play"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            if (window.innerWidth <= 840) {
                              e.preventDefault();
                              setModal("register-packdraw");
                            }
                          }}
                        >
                          <div className="nav__dropdown-play-element"></div>
                          <div className="nav__dropdown-play-title">
                            {polygonIcon}
                            <h6>{t("playPackdraw")}</h6>
                          </div>
                          <div className="nav__dropdown-play-pack">
                            <img src={packdraw} alt="Packdrow" />
                          </div>
                          <div className="nav__dropdown-play-pack hov">
                            <img src={packdrawLight} alt="Packdrow Light" />
                          </div>
                        </a>
                        <div className="nav__dropdown-col-wrapper">
                          <div className="nav__dropdown-row">
                            <div
                              onClick={() => {
                                setModal("bonus");
                                setBonusData("packdraw");
                                setMenu(false);
                              }}
                              className="nav__dropdown-row-image"
                            >
                              <img src={deposit} alt="Deposit" />
                            </div>
                            <div
                              onClick={() => {
                                setModal("bonus");
                                setBonusData("packdraw");
                                setMenu(false);
                              }}
                              className="nav__dropdown-row-image"
                            >
                              <img src={rolex} alt="Rolex" />
                            </div>
                            <div
                              onClick={() => {
                                setModal("bonus");
                                setBonusData("packdraw");
                              }}
                              className="nav__dropdown-row-image"
                            >
                              <img src={iphone} alt="iPhone" />
                            </div>
                          </div>
                          <div className="nav__dropdown-content">
                            <div
                              className="nav__dropdown-item"
                              onClick={() => {
                                setMenu(false);
                                setModal("register-packdraw");
                              }}
                            >
                              <div className="nav__dropdown-item-title">
                                <h5>?</h5>
                                <h6>{t("howToRegister")}</h6>
                              </div>
                              <div className="nav__dropdown-item-bg"></div>
                              <div className="nav__dropdown-item-bg hov"></div>
                              <div className="nav__dropdown-item-image">
                                <img src={packLoginPic} alt="Packdraw Login" />
                              </div>
                            </div>
                            <Link
                              to="/leaderboard-packdraw"
                              aria-label="leaderboard-packdraw"
                              className="nav__dropdown-leaderboard"
                            >
                              <img
                                src={leaderboard}
                                alt="Leaderboard Packdraw"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="nav__inner-link shop"
                    onClick={() => {
                      setMenu(false);
                      navigate("/shop");
                    }}
                    onMouseOver={() => {
                      setRoobetHov(false);
                      setPackdrowHov(false);
                    }}
                  >
                    <div className="nav__inner-link-content">
                      <p>Shop</p>
                    </div>
                    <div className={"nav__dropdown pack "}>
                      <div
                        className="nav__dropdown-title shop"
                        onClick={() => {
                          navigate("/shop");
                        }}
                      >
                        <img src={shard} alt="" />
                        <div className="nav__dropdown-title-image">
                          <img src={clouds} alt="" />
                        </div>
                        <p>{t("Shop")}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="nav__inner-link vip"
                    onClick={() => {
                      setMenu(false);
                      navigate("/vip");
                    }}
                    onMouseOver={() => {
                      setRoobetHov(false);
                      setPackdrowHov(false);
                    }}
                  >
                    <div className="nav__inner-link-content">
                      {crownIcon2}
                      <p>
                        <span>VIP</span> Rewards
                      </p>
                    </div>
                    <div className={"nav__dropdown pack "}>
                      <div
                        className="nav__dropdown-title vip"
                        onClick={() => {
                          navigate("/vip");
                        }}
                      >
                        <div className="nav__dropdown-title-bg">
                          <img src={vipBg} alt="" />
                        </div>
                        <div className="nav__dropdown-title-star one">
                          <img src={star1} alt="" />
                        </div>
                        <div className="nav__dropdown-title-star two">
                          <img src={star2} alt="" />
                        </div>
                        <div className="nav__dropdown-title-star three">
                          <img src={star3} alt="" />
                        </div>
                        <div className="nav__dropdown-title-icon">
                          <img src={vipSmall} alt="" />
                        </div>

                        <p>VIP Rewards</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "burger " +
                  (modal === "notif" ? "active" : menu ? "active" : "")
                }
                onClick={() => {
                  if (modal === "notif") {
                    clearNotifications().then((r) => {
                      setMenu(false);
                      setModal(null);
                      dispatch(setUnreadNotification(0));
                      handleToast("success", "Notification cleared");
                    });
                  } else {
                    setMenu(!menu);
                    setModal(null);
                  }
                }}
              >
                <span></span>
                {modal === "notif" ? trashIcon : navCloseIcon}
              </div>
            </div>
            {!auth?.isLogged && (
              <>
                <div
                  className="header__btns"
                  onMouseOver={() => {
                    setPackdrowHov(false);
                    setRoobetHov(false);
                  }}
                >
                  <button
                    type="button"
                    className="button auth login"
                    onClick={() => setModal("login")}
                  >
                    <span className="default"></span>
                    <span className="hov"></span>
                    <span className="active"></span>
                    <p>{t("login")}</p>
                  </button>
                  <button
                    type="button"
                    className="button auth register"
                    onClick={() => setModal("register")}
                  >
                    <span className="default"></span>
                    <span className="hov"></span>
                    <span className="active"></span>
                    <p>{t("register")}</p>
                  </button>
                </div>
                <LanguageSelect
                  list={languageList}
                  selected={selectedLanguage}
                  onChange={handleLanguageChange}
                />
              </>
            )}
          </div>
        </div>
        <div className={"header__more"}>
          {auth?.isLogged && (
            <>
              {/* <button type="button" className="header__more-level">
                    <div className="header__more-level-progress">
                      <span style={{width: "0%"}}></span>
                    </div>
                    <div className="header__more-level-info">
                      <img src={lvl1} alt=""/>
                    </div>
                  </button> */}

              <button
                type="button"
                className="header__more-account"
                onClick={() => navigate("/profile", { state: "account" })}
              >
                <p>{auth?.user?.username}</p>
                <div className="header__more-account-avatar">
                  {auth?.user?.profilePicture ? (
                    <img src={auth?.user?.profilePicture} alt="" />
                  ) : (
                    avatarIcon
                  )}
                </div>
              </button>

              <div className="header__more-balance">
                <p>{formatNumberComma(auth?.user?.balance.toString())}</p>
                <img src={shards} alt="Shards Icon" />
              </div>
              <Notification setModal={setModal} modal={modal} />
              {/* <button className="header__more-notify">
                  {notifyIcon} {auth?.user?.notificationSummary?.countUnread}
                </button> */}
            </>
          )}

          {auth?.isLogged && (
            <>
              <div className="header__more-btns">
                <LanguageSelect
                  list={languageList}
                  selected={selectedLanguage}
                  onChange={handleLanguageChange}
                />
                <button
                  className="header__more-chat"
                  onClick={() => dispatch(logoutUser())}
                >
                  {logoutIcon}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <AnimatePresence>
        {(roobetHov || packdrowHov) && (
          <motion.div
            className="bgOverlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0 }}
          ></motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
